<template>
  <div class="map-app d-flex flex-row">
    <map-devices @hideMark="toggleMark" @showMark="toggleMark" :markers="markers" />
    <div class="map-app d-flex flex-column">
      <gmaps-Map @mounted="mapReady" ref="map">
        <gmaps-Marker
          v-for="(item, i) in markers"
          :key="i"
          :position="item.position"
          :options="item.m"
          :icon="item.m.icon == '' ? '' : getIcon(item.m.icon)"
          @click="showInfo(item)"
          :visible="item.m.visible"
        >
        </gmaps-Marker>
        <gmaps-info-window ref="info" :options="winInfo">
          <div>
            <span style="background: yellow">{{ winInfo.m.name }}</span> <br />
            <span>{{ winInfo.m.uniqueid }}</span> <br />
            <span>{{ getDateString(winInfo.m.creado) }}</span> <br />
            <span>Vel: {{ winInfo.m.speed }}</span>
            <br />
            <v-btn icon small :href="goToMap(winInfo.position)" target="_blank">
              <v-icon>mdi-google-maps</v-icon>
            </v-btn>
          </div>
        </gmaps-info-window>
      </gmaps-Map>
      <position-table
        :itemsPerPage="-1"
        :items="
          markers.map((it) => {
            it.m.deviceName = it.m.name;
            return it.m;
          })
        "
      ></position-table>
    </div>
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker, gmapsInfoWindow } from "x5-gmaps";
import { mapActions } from "vuex";

export default {
  metaInfo: { title: "Vigilante" },
  components: {
    gmapsMap,
    gmapsMarker,
    gmapsInfoWindow,
    mapDevices: () => import("./sections/vigilante/mapDevices.vue"),
    positionTable: () => import("./sections/vigilante/PositionTable.vue"),
  },
  data: () => ({
    markers: [],
    map: null,
    winInfo: {
      disableAutoPan: true,
      pixelOffset: { height: -35 },
      position: { lat: 0, lng: 0 },
      m: {},
    },
    interval: null,
    iconSize: null,
    animation: {
      delay: 10,
      iteration: 0,
      numDeltas: 100,
    },
  }),
  mounted() {
    const self = this;
    this.interval = setInterval(function () {
      self.initialLoad(self.map);
    }, 60 * 1000);
    //this.$refs.info.close();
    //this.initialLoad();
    //console.log(this.interval);
    // this.$refs.info.close();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["getLatestPositions", "showSnackbar"]),
    getDateString(date) {
      if (date == undefined) return "";
      var dformat = this.$func.getFullDateString(date);

      return dformat;
    },
    mapReady(map) {
      this.map = map;
      //            console.log(google);
      this.initialLoad(map, true);
    },
    showInfo(marker) {
      this.winInfo = marker;
      this.$refs.info.open();
    },
    goToMap(position) {
      return (
        "https://maps.google.com/maps?f=q&q=" +
        position.lat +
        "," +
        position.lng +
        "&z=16"
      );
    },
    initialLoad(map, bounds) {
      bounds = bounds || false;
      var profile = this.$route.params?.id;
      //console.log(profile);
      this.getLatestPositions(profile || 0)
        .then((res) => {
          //console.log(res);
          if (res.data.data.length == 0) {
            this.showSnackbar({
              showing: true,
              message: "No hay registros, revisa que tus unidades esten activas.",
              color: "success",
            });
            clearInterval(this.interval);
            return;
          }
          //this.markers = res.data.data.map((m)=> { return {position:{lat:m.latitude,lng:m.longitude},m}; });
          //this.markers = res.data.newdata;

          if (bounds) {
            this.markers = res.data.newdata;
            this.updateBounds(map);
          } else this.animateMarkers(res.data.newdata);

          //console.log(this.markers);
        })
        .catch((err) => {
          console.log("latest Err" + err);
        });
    },
    animateMarkers(data) {
      this.animation.iteration = 0;

      for (var j = 0; j < this.markers.length; j++) {
        var currDevid = this.markers[j].m.deviceid;
        var dataPos = data.find((m) => m.m.deviceid == currDevid);

        //set new data except position
        this.markers[j].m = dataPos.m;

        //set deltas
        this.markers[j]["delta"] = {};
        this.markers[j]["delta"]["lat"] =
          (dataPos.position.lat - this.markers[j].position.lat) /
          this.animation.numDeltas;
        this.markers[j]["delta"]["lng"] =
          (dataPos.position.lng - this.markers[j].position.lng) /
          this.animation.numDeltas;
      }

      this.moveMarkers();
    },
    moveMarkers() {
      for (var j = 0; j < this.markers.length; j++) {
        this.markers[j].position.lat += this.markers[j]["delta"]["lat"];
        this.markers[j].position.lat += this.markers[j]["delta"]["lng"];
      }
      //this.markers[i].position.lat = deltaLat;
      //this.markers[i].position.lng = deltaLng;

      if (this.animation.iteration != this.animation.numDeltas) {
        this.animation.iteration++;
        setTimeout(this.moveMarkers, this.animation.delay);
      }
    },
    updateBounds(map) {
      /*global google*/
      var bounds = new google.maps.LatLngBounds();
      this.iconSize = new google.maps.Size(50, 50);

      this.markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
      });
      map.fitBounds(bounds);
    },
    toggleMark(devid) {
      this.markers = this.markers.map((m) => {
        if (m.m.deviceid == devid) {
          m.m.visible = !m.m.visible;
          //console.log(m.m);
        }
        return m;
      });
      //console.log('new markers: ', this.markers);
    },
    getIcon(icon) {
      return {
        url: icon,
        scaledSize: this.iconSize,
      };
    },
  },
};
</script>

<style>
.map-app {
  height: 100%;
  width: 100%;
}
</style>
